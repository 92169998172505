export const getTotalCost = (price, count = 1, insurance = 0) => {
  return (+((price * count) + (insurance * count)).toFixed(2));
}

export const getNextPrice = (obj) => {
  const { prices, limits, tickets_left, price } = obj;
  const soldOut = limits?.athlete - tickets_left?.athlete; // кол-во проданных билетов
  const currentIndex = prices.findIndex(el => el.price === price);
  const nextPrice = prices[currentIndex + 1];
  // const currentPrice = prices[currentIndex];

  if (nextPrice && tickets_left > 0) {
    const leftTicket = nextPrice.tickets_count - soldOut;
    return { tickets_left: { athlete: leftTicket }, nextPrice: nextPrice.price};
  } else {
    // остаток по текущей цене = остатку на формат
    return { tickets_left: { athlete: tickets_left.athlete }, nextPrice: null };
  }
};
