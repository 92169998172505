import { getLink } from 'geolinks';
import { ReactComponent as YandexLogo } from '../../assets/images/svg/yandex-logo.svg';
import { ReactComponent as GoogleLogo } from '../../assets/images/svg/google-logo.svg';
import metroIcon from '../../assets/images/nav/metro.svg';
import navigIcon from '../../assets/images/nav/navig.svg';
import giftIcon from '../../assets/images/nav/gift.svg';
import questIcon from '../../assets/images/nav/quest.svg';

// import mapMob1xMin from '../../assets/images/nav/map-mob1x-min.jpg';
// import mapMob2xMin from '../../assets/images/nav/map-mob2x-min.jpg';
// import map1xMin from '../../assets/images/nav/map1x-min.jpg';
// import map2xMin from '../../assets/images/nav/map2x-min.jpg';
import MapComponent from '../map/Map';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedCity } from '../../store/app/selectors';

function WhereIs() {
  const city = useSelector(getSelectedCity);
  const [googleMapLink, setGoogleMapLink] = useState("/");
  const [yandexMapLink, setYandexMapLink] = useState("/");

  useEffect(() => {
    if (city && Object.keys(city).length > 0 && Object.keys(city.geo).length > 0) {
      setGoogleMapLink(getLink("google", {lat: city.geo.lat, lng: city.geo.lon, zoom: 16, language: `ru`} ));
      setYandexMapLink(getLink("yandex", {lat: city.geo.lat, lng: city.geo.lon, zoom: 16} ));
    }
  }, [city]);

  return (
    <section className="nav container container--big" id="whereis">
      {/*<h2 className="nav__title">Как добраться</h2>*/}
      <ul className="nav__list">
        <li className="nav__item">
          <img className="nav__image" src={metroIcon} alt="Полигон" />
            <div className="nav__textWrap">
              <b className="nav__subtitle">Трансфер</b>
              <p>{city?.info?.public_transport ?? 'Информация в разработке'}</p>
            </div>
        </li>
        <li className="nav__item nav__item--navig">
          <img className="nav__image" src={navigIcon} alt="Free" />
            <div className="nav__textWrap">
              <b className="nav__subtitle">Построить маршрут</b>
              <p className="nav__logos">
                <a className="nav__logo nav__logo--yandex" href={yandexMapLink} target="_blank" rel="noreferrer">
                  <YandexLogo width="196" height="50" />
                </a>
                <a href={googleMapLink} target="_blank" rel="noreferrer">
                  <GoogleLogo width="196" height="50" />
                </a>
              </p>
            </div>
        </li>
        <li className="nav__item">
          <img className="nav__image" src={giftIcon} alt="Free" />
            <div className="nav__textWrap">
              <b className="nav__subtitle">Приезжайте с&nbsp;болельщиками, для&nbsp;них вход бесплатный!</b>
            </div>
        </li>
        <li className="nav__item">
          <img className="nav__image" src={questIcon} alt="Free" />
            <div className="nav__textWrap">
              <b className="nav__subtitle nav__subtitle--small">По вопросам корпоративного участия</b>
              <a className="btn nav__btn" href="https://heroleague.ru/corp#corpForm"
                 target="_blank" rel="noopener noreferrer">Оставьте обращение</a>
            </div>
        </li>
      </ul>

      <div className="nav__picture">
        <MapComponent />
        {/*<picture>*/}
        {/*  <source media="(max-width: 767px)"*/}
        {/*          srcSet={`${mapMob1xMin} 1x, ${mapMob2xMin} 2x`} />*/}
        {/*    <img className="nav__map" src={map1xMin}*/}
        {/*         srcSet={`${{map2xMin}} 2x`} alt="Карта" />*/}
        {/*</picture>*/}
      </div>
    </section>
  );
}

export default WhereIs;
