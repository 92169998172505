import iconOption1 from '../../assets/images/options/icon-option1.png';
import iconOption2 from '../../assets/images/options/icon-option2.png';
import iconOption3 from '../../assets/images/options/icon-option3.png';
import iconOption4 from '../../assets/images/options/icon-option4.png';
import iconOption5 from '../../assets/images/options/icon-option5.png';
import iconOption6 from '../../assets/images/options/icon-option6.png';
import iconOption7 from '../../assets/images/options/icon-option7.png';
import iconOption8 from '../../assets/images/options/icon-option8.png';


const options = [
  [
    {
      id: 1,
      label: `Горячая еда и чай`,
      icon: iconOption2,
    },
    {
      id: 2,
      label: `Бесплатная парковка`,
      icon: iconOption1,
    },
  ],
  [
    {
      id: 3,
      label: `Бесплатный трансфер до Ж/Д станции`,
      icon: iconOption3,
    },
    {
      id: 4,
      label: `Каждый сезон новый жетон`,
      icon: iconOption4,
    },
  ],
  [
    {
      id: 5,
      label: `Камера хранения `,
      icon: iconOption5,
    },
    {
      id: 6,
      label: `Теплая раздевалка `,
      icon: iconOption6,
    },
  ],
  [
    {
      id: 7,
      label: `Команда волонтеров`,
      icon: iconOption7,
    },
    {
      id: 8,
      label: `Медицинская помощь`,
      icon: iconOption8,
    },
  ],
];

export {
  options,
};
