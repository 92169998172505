const cityRoutes = [
  {
    id: 0,
    path: `/:customPath`,
  },
  {
    id: 1,
    path: `/:customPath/moscow`,
    cityName: `moscow`,
    cityId: 524901,
  },
  {
    id: 2,
    path: `/:customPath/murmansk`,
    cityName: `murmansk`,
    cityId: 524305,
  },
  {
    id: 3,
    path: `/:customPath/spb`,
    cityName: `spb`,
    cityId: 498817,
  },
  {
    id: 4,
    path: `/:customPath/ufa`,
    cityName: `ufa`,
    cityId: 479561,
  },
];

export { cityRoutes };
