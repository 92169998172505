import { NameSpace } from '../root-reducer';

const getSelectedCity = (state) => state[NameSpace.APP].selectedCity;
const getEventsList = (state) => state[NameSpace.APP].events;
const getFormatList = (state) => state[NameSpace.APP].formats;
const getOpenPageStatus = (state) => state[NameSpace.APP].isFirstOpenPage;
const getFormatCity = (state) => state[NameSpace.APP].formatCity;

export {
  getSelectedCity,
  getEventsList,
  getFormatList,
  getOpenPageStatus,
  getFormatCity,
};
