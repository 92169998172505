import { createReducer } from '@reduxjs/toolkit';
import {setEventsList, setFormatCity, setFormatList, setOpenPageStatus, setSelectedCity} from '../action';

const initialState = {
  selectedCity: null,
  formatCity: null,
  events: null,
  formats: [],
  isFirstOpenPage: true,
};

const app = createReducer(initialState, (builder) => {
  builder
    .addCase(setSelectedCity, (state, action) => {
      state.selectedCity = action.payload;
    })
    .addCase(setEventsList, (state, action) => {
      state.events = action.payload;
    })
    .addCase(setFormatList, (state, action) => {
      state.formats = action.payload;
    })
    .addCase(setOpenPageStatus, (state, action) => {
      state.isFirstOpenPage = action.payload;
    })
    .addCase(setFormatCity, (state, action) => {
      state.formatCity = action.payload;
    })
});

export { app }
