import styles from './Formats.module.scss';
import { useState } from 'react';
import FormatBuyPopup from '../popups/formatBuy/FormatBuyPopup';
import { getNextPrice } from '../../utils/goods';
import Container from '../Container/Container';
import { useSelector } from 'react-redux';
import { getFormatList } from '../../store/app/selectors';


const Formats = () => {
  const formats = useSelector(getFormatList);
  const [format, setFormat] = useState(null);
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [focusFormat, setFocusFormat] = useState(null);

  const handleFormatClick = (format) => {
    setFormat(format);
    setOpenPopup(true);
  };

  return (
    <section className={styles.formats}>
      <Container>
        <h2 className={styles.title}>Форматы</h2>

        <ul className={styles.list} id="formats">
          { formats.length > 0 && formats.map((format, index) => (
            <li className={`${styles.item} ${focusFormat === index ? styles.itemFocused : null}`} key={format.public_id}>
              <div className={styles.itemBody}>
                <h3 className={styles.itemTitle}>{format.title}</h3>
                <p className={styles.text}>{format.description}</p>

                {/*<p className={`${styles.priceNext} ${styles.ticketsCount}`}>*/}
                <p className={styles.costCurrent}>
                  <span>Осталось:</span> <span className={styles.costNextValue}>{getNextPrice(format).tickets_left.athlete}</span>
                </p>

                <div className={styles.costWrap}>
                  <span className={styles.costText}>Стоимость:</span>
                  <span className={`${styles.cost} ${focusFormat === index ? styles.costFocused : null}`}>
                    {format.team_all ? format.price * format.max_count : format.price} руб
                  </span>
                </div>
                <div>

                  { getNextPrice(format).nextPrice != null &&
                  <p className={`${styles.costWrap} ${styles.costWrapNext}`}>
                    <span>Следующая цена:</span> <span className={styles.costNext}>{format.team_all ? getNextPrice(format).nextPrice * format.max_count : getNextPrice(format).nextPrice} ₽</span>
                  </p>
                  }
                </div>
              </div>

              <button
                className={styles.btn} type="button"
                onClick={() => handleFormatClick(format)}
                onMouseEnter={() => setFocusFormat(index)}
                onMouseLeave={() => setFocusFormat(null)}
                disabled={format.tickets_left < 1}
              >
                {format.tickets_left.athlete < 1 ? `Распродано` : `Купить`}
              </button>
            </li>
          ))  }

          <li className={`${styles.item} ${focusFormat === `corp` ? styles.itemFocused : null}`}>
            <div className={styles.itemBody}>
              <h3 className={styles.itemTitle}>Корпоративное участие</h3>
              <p className={styles.text}>
                Чтобы ваша компания стала частью Гонки Героев, необходимо заполнить простую форму и оставить заявку.
              </p>
            </div>

            <a
              className={styles.btn}
              href="/corp"
              onClick={() => handleFormatClick(format)}
              onMouseEnter={() => setFocusFormat(`corp`)}
              onMouseLeave={() => setFocusFormat(null)}
            >
              Участвовать
            </a>
          </li>
        </ul>

      </Container>

      { isOpenPopup && <FormatBuyPopup handleStatePopup={setOpenPopup} format={format} /> }
    </section>
  );
}

export default Formats;
