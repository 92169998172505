import styles from './MainScreen.module.scss';
import PageTop from '../components/PageTop/PageTop';
import Description from '../components/Description/Description';
import Features from '../components/Features/Features';
import Levels from '../components/Levels/Levels';
import Options from '../components/Options/Options';
import Gallery from '../components/Gallery/Gallery';
import Formats from '../components/formats/Formats';
import Corp from '../components/Corp/Corp';
// import Container from '../components/Container/Container';
// import Ad from '../components/ad/Ad';
// import { adBanners } from '../components/ad/ad-data';
import Faq from '../components/faq/Faq';
import WhereIs from '../components/WhereIs/WhereIs';
import Footer from '../components/Footer/Footer';
import CityChoice from '../components/cityChoice/CityChoice';
// import Engraving from '../components/Engraving/Engraving';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getEventsList, getOpenPageStatus, getSelectedCity } from '../store/app/selectors';
import { fetchEventsList } from '../store/api-actions';
import { setCitiesOptions } from '../utils/common';
import {setFormatCity, setFormatList, setOpenPageStatus, setSelectedCity} from '../store/action';
import api from '../api';
import { cityRoutes } from '../const/routes';
import Partners from "../components/Partners/Partners";


function MainScreen({cityIdScreen}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [registrationClosed, setRegistrationClosed] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [defaultCityOption, setDefaultCityOption] = useState({});

  const events = useSelector(getEventsList);
  const selectedCity = useSelector(getSelectedCity);
  const isFirstOpenPage = useSelector(getOpenPageStatus);

  const registrationCloseFilter = (event_city) => {
    const registrationCloseTime = new Date(event_city.registration_close * 1000);
    const currentTime = new Date();
    if (registrationCloseTime < currentTime) {
      return true
    } else {
      return false
    }
  };

  useEffect(() => {
    !events && dispatch(fetchEventsList());

    if (!selectedCity && events) {
      const dateNow = Math.round(Date.now() / 1000);

      if (cityIdScreen) {
        const filterCity = events.filter((el) => el.city.id === cityIdScreen);
        const foundCity = filterCity.find((el) => el.city.id === cityIdScreen && el.registration_close > dateNow) ?? filterCity[filterCity?.length - 1];
        const cityOption = setCitiesOptions(events).find((el) => el.cityPublicId === foundCity.public_id);

        setDefaultCityOption(cityOption);
        dispatch(setSelectedCity(foundCity));
      } else {

        setDefaultCityOption(setCitiesOptions(events)[0]);
        dispatch(setSelectedCity(events[0]));
      }

      setCityOptions(setCitiesOptions(events));
    }
  }, [events]);

  useEffect(() => {
    if (events && selectedCity) {
      const cityOption = setCitiesOptions(events).find((el) => el.value === selectedCity.public_id);

      setCityOptions(setCitiesOptions(events));
      setDefaultCityOption(cityOption);
      setRegistrationClosed(registrationCloseFilter(selectedCity));
      fetchFormats(selectedCity.public_id);
    }
  }, [selectedCity]);

  const fetchFormats = async (publicId) => {
    const eventCityPublicId = publicId || selectedCity.public_id;
    const filterCity = events.filter((el) => el.city.id === cityIdScreen); // поиск всех мероприятий в выбранном городе
    const currentCityIndex = filterCity.findIndex((el) => el.public_id === publicId);

    try {
      const response = await api.get(`/api/event_format/event/${eventCityPublicId}`);

      if (response.data) {
        if (response?.data?.values?.length > 0) {
          const formatTicketsLeft = response.data.values.find((el) => el?.tickets_left?.athlete > 0);

          if (isFirstOpenPage && !formatTicketsLeft && currentCityIndex < (filterCity.length - 1)) {
            const nextCity = filterCity[currentCityIndex + 1];

            dispatch(setSelectedCity(nextCity));
            setDefaultCityOption(setCitiesOptions([nextCity])[0]);
          } else {
            dispatch(setFormatList(response.data.values));
            dispatch(setFormatCity(response.data.city));
          }

        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeCity = (evt) => {
    const city = cityRoutes.find((el) => el.cityId === evt.cityId);

    dispatch(setOpenPageStatus(false));

    if (city) {
      const foundCity = events.find((el) => el.public_id === evt.value);

      history.push(`/zima/${city.cityName}`);
      dispatch(setSelectedCity(foundCity));
    }
  };

  return (
    <>
      <h1 className="visually-hidden">Гонка Героев Зима</h1>

      <PageTop cityOptions={cityOptions} defaultCityOption={defaultCityOption} onChangeCity={handleChangeCity} />
      <Description />
      <Features />
      <Levels />
      <Options />
      <Gallery />
      <div className={styles.containerChoice}>
        <CityChoice cityOptions={cityOptions} defaultCityOption={defaultCityOption} onChangeCity={handleChangeCity} />
      </div>
      <Formats />
      <Corp />
      <Partners />
      {/*<Container>*/}
      {/*  <Ad ad={adBanners[1]} />*/}
      {/*</Container>*/}
      {/* <Engraving /> */}
      <Faq />
      <WhereIs />
      <Footer />
    </>
  );
}

export default MainScreen;
