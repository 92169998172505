import iconFire from '../../assets/images/svg/icon-fire.png';
import iconMedal from '../../assets/images/svg/icon-medal.png';
import iconHand from '../../assets/images/svg/icon-hand.png';

const features = [
  {
    id: 1,
    title: `Будет жарко!`,
    text: `Это 100%! Помимо того, что ты будешь постоянно в движении, на финише тебя ждут обогреваемые раздевалки и шатры, только что приготовленная еда и горячий чай.`,
    icon: iconFire,
  },
  {
    id: 2,
    title: `Проигравших нет!`,
    text: `Зимнюю трассу Гонки Героев пройдёт даже новичок. Преодолевать препятствия по снегу гораздо проще. И если даже 
    что-то пойдет не так, то профессиональные инструкторы и команда волонтеров всегда помогут тебе.`,
    icon: iconMedal,
  },
  {
    id: 3,
    title: `Тебя ждут новые друзья!`,
    text: `Если тебе не с кем бежать, мы станем твоими друзьями и познакомим с другими участниками. Ты точно не останешься один!`,
    icon: iconHand,
  },
];

export {
  features,
};
