import styles from './PageTop.module.scss';
import { ReactComponent as RaceLogo } from '../../assets/images/logo-race.svg';
import RaceWinter from '../../assets/images/logo-winter.png';
import Header from '../header/Header';
import { times } from '../../utils/times';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getFormatList } from '../../store/app/selectors';
import CityChoice from '../cityChoice/CityChoice';


function PageTop({ cityOptions, defaultCityOption, onChangeCity }) {
  const formats = useSelector(getFormatList);
  const isFormats = formats.length > 0;
  const startTime = isFormats ? times.getDayOfWeek(formats[0].start_time) : null;
  const address = isFormats ? formats[0].address : null;
  const topRef = useRef(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    return () => {
      ScrollTrigger.getAll().forEach((instance) => {
        instance.kill();
      });

      gsap.killTweensOf(window);
    };
  }, []);

  useEffect(() => {
    const topEl = topRef.current;
    const options = {
      trigger: topEl,
      pin: true,
      pinSpacing: false,
      scrub: true,
    };

    ScrollTrigger.matchMedia({
      "(min-width: 768px) and (max-height: 990px)": function () {
        gsap.to(
          topEl,
          {
            scrollTrigger: {
              ...options,
              start: "bottom 70%",
            }
          },
        )
      },
      "(min-width: 768px) and (min-height: 990px)": function () {
        gsap.to(
          topEl,
          {
            scrollTrigger: {
              ...options,
              start: "top top",
            }
          },
        )
      }
    })
  }, []);

  return (
    <div>
      <section className={styles.pageTop} ref={topRef} >
        <Header formats={formats} />

        <div className={styles.content}>
          <RaceLogo className={styles.raceLogo} />
          <img className={styles.logoWinter} src={RaceWinter} alt=""/>
          <span className={`${styles.timeAddress}`}>{startTime}</span>
          <span className={`${styles.timeAddress} ${styles.address}`}>{address}</span>
          <p className={styles.desc}>
            Участвуй в жаркой зимней гонке с препятствиями!
          </p>
          <CityChoice cityOptions={cityOptions} defaultCityOption={defaultCityOption} onChangeCity={onChangeCity} />
          <a className={styles.link} href="#formats">Принять участие</a>
        </div>
      </section>


    </div>
  );
}

export default PageTop;
