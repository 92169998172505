import styles from './Title.module.scss';
// import { useRect } from '../../customHooks/useRect';
// import { useState } from 'react';


// function ShadowTitles({text}) {
//   const tittles = [];
//
//   for (let i = 0; i < 3; i++) tittles.push(text);
//
//   return tittles.map((item) => <span className={styles.shadowTitle}>{item} </span>);
// }

function Title({children, shadowTitle}) {
  // const [titleRect, titleRef] = useRect();
  //
  // useState(() => {
  //   console.log(titleRect.width)
  // })

  return (
    <h2 className={styles.title}>
      {/*<span className={`${styles.leftTitles} ${styles.shadowWrap}`}>{generateTitle(shadowTitle)}</span>*/}
      <span>{children} </span>
      {/*<span className={styles.shadowWrap}><ShadowTitles text={shadowTitle} /></span>*/}
    </h2>
  );
}

export default Title;
