import styles from './Header.module.scss';
import { ReactComponent as Logo } from '../../assets/images/logo-lh-white.svg';
import { ReactComponent as IconCart } from '../../assets/images/svg/icon-cart.svg';
import { ReactComponent as IconAuth } from '../../assets/images/svg/icon-auth.svg';
import { useState } from 'react';
import BuyEventPopup from '../popups/BuyEvent/BuyEventPopup';
import HideScroll from '../common/hide-scroll/HideScroll';
import {useSelector} from "react-redux";
import {getFormatCity} from "../../store/app/selectors";
// import { Link } from 'react-router-dom';

const Links = [
  {id: 0, name: `Мероприятия`, link: `https://heroleague.ru/calendar`,},
  // {id: 1, name: `Виртуальные события`, link: `#position`,},
  // {id: 2, name: `Тренировки`, link: `#reg`,},
  {id: 1, name: `Магазин`, link: `https://heroleague.ru/shop/`,},
  {id: 2, name: `Партнёрство`, link: `https://heroleague.ru/partners#application`,},
  {id: 3, name: `Корпоративным клиентам`, link: `https://heroleague.ru/corp#corpForm`,},
  // {id: 4, name: `Результаты`, link: `/trail/results`,},
  {id: 4, name: `О нас`, link: `https://heroleague.ru/about`,},
  {id: 5, name: `Как добраться`, link: `#whereis`,},
];

const Header = ({formats}) => {
  const city = useSelector(getFormatCity);
  const domain = process.env.REACT_APP_API;

  const [stateMenu, setStateMenu] = useState(false);
  const [statePopup, setStatePopup] = useState(false);

  const handleMenuState = (evt) => {
    evt.preventDefault();
    setStateMenu(prev => !prev);
  }

  return (
    <header className={styles.header} data-state={stateMenu}>
      <h1 className="visually-hidden">Гонка Героев Зима</h1>
      {stateMenu && <HideScroll />}
      {/*<Link to="/trail">*/}
        <Logo className={styles.logo} width="72" height="48" />
      {/*</Link>*/}
      <div className={styles.navMobile}>
        <ul className={styles.list}>
          {Links.map(link => (
            <li key={link.id} className={styles.item}><a className={styles.link} href={link.link} onClick={() => setStateMenu(false)}>{link.name}</a></li>
          ))}

          {city?.info?.legals && (
            <li className={styles.item}>
              <a className={styles.link} href={`${domain}${city.info.legals}`} target="_blank"
                 rel="noreferrer">Положение</a>
            </li>
          )}
        </ul>
        {formats?.length > 0 && <button className={styles.btn} type="button" onClick={() => setStatePopup(true)}>Быстрая покупка</button>}
        {/*<a href="/trail/results" className={styles.btn}>Результаты</a>*/}
        <div className={styles.btnWrap}>
          <a className={`${styles.headerLink} ${styles.link}`} href="https://heroleague.ru/basket" target="_blank" rel="noreferrer">
            <IconCart />
            <span>В корзину</span>
          </a>
          <a className={`${styles.headerLink} ${styles.link}`} href="https://heroleague.ru/login" target="_blank" rel="noreferrer">
            <IconAuth />
            <span className="visually-hidden">Авторизация</span>
          </a>
        </div>
      </div>
      <button className={styles.btnMenuMobile} aria-label="Меню" onClick={handleMenuState}>
        <div className={styles.gam} />
      </button>

      {statePopup && <BuyEventPopup statePopupHandler={setStatePopup} formats={formats} />}
    </header>
  );
}

export default Header;
