import { useState } from 'react';
import { useCount } from '../../../../customHooks/useCount';
import { getTotalCost } from '../../../../utils/goods';
import { getCurrentPrice } from '../../../../utils/times';
import { HERO_API } from '../../../../api';

const BuyEventInfo = ({format, id, styles}) => {
  const {
    insurance_available,
    insurance_cost,
    price,
    tickets_left,
    prices,
    team_all,
    max_count

  } = format;

  // {format.team_all ? format.price * format.max_count : format.price}

  const [insurance, setInsurance] = useState(false);
  // const [count, increment, decrement] = useCount(1);
  const [count] = useCount(team_all ? max_count : 1);
  const handleChangeInsurance = () => setInsurance(!insurance);

  const handleRedirectChange = (evt) => {
    evt.preventDefault();

    window.location.href = `${HERO_API}/basket-fill-outside/event?public_id=${format.event_public_id}&city_id=${format.city.id}&format_id=${format.public_id}&insurance=${insurance}&count=${count}`;
  };

  return (
    <div className={styles.accordionContent}>
      <p className={styles.countWrap}>
        Осталось мест:&nbsp;
        <span className={styles.count}>{tickets_left.athlete}</span>
      </p>
      <div className={styles.info}>
        {insurance_available &&
          <div>
            <p className={styles.infoLabel}>Страховка</p>
            <div className={styles.checkboxWrap}>
              <input className={`${styles.checkbox} visually-hidden`} type="checkbox"
                id={`check-${id}`}
                onChange={handleChangeInsurance}
              />
              <label className={styles.checkboxLabel} htmlFor={`check-${id}`}>Включить страховой полис</label>
              <p className={styles.insText}>(Страховка для лиц 18+)</p>
            </div>
          </div>
        }
        {/*<div className={styles.cell}>*/}
        {/*  <p className={styles.infoLabel}>Кол-во</p>*/}
        {/*  <p className={styles.countBtnsGroup}>*/}
        {/*    <button className={styles.countBtn} type="button" onClick={decrement}>-</button>*/}
        {/*    <span className={styles.count}>{count}</span>*/}
        {/*    <button className={styles.countBtn} type="button" onClick={increment}>+</button>*/}
        {/*  </p>*/}
        {/*</div>*/}
        <div className={styles.price}>
          <p className={styles.infoLabel}>Итого:</p>
          <p className={styles.totalPrice}>
            {getTotalCost(price, count, insurance && insurance_cost)}&nbsp;&#8381;
          </p>
          {/*{getCurrentPrice(prices) && <p className={styles.nextPrice}>*/}
          {/*  Следующая цена: {getCurrentPrice(prices).price} &#8381;*/}
          {/*</p>}*/}
        </div>
      </div>
      <button className={styles.btnCart} onClick={handleRedirectChange} disabled={format.tickets_left.athlete < 1}>{format.tickets_left.athlete < 1 ? `Распродано` : `В корзину`}</button>
    </div>
  )
}

export default BuyEventInfo;
