const faqQuestions = [
  {
    id: 0,
    question: `Что такое Гонка Героев Зима?`,
    answer: `Это экстремальный забег с препятствиями по пересеченной местности в условиях зимы, организованный командой “Лиги Героев”. Принять участие может любой желающий старше 18 и при наличии медицинской справки. Зимний формат Гонки Героев проходит в разных городах и, как правило, открывает спортивный сезон.`,
  },
  {
    id: 1,
    question: `Что надеть на Гонку Героев Зима?`,
    answer: `Одежда должна быть удобной и не сковывать движения. В ней должно быть прохладно, когда стоишь, и тепло, когда активно двигаешься.
      Мы советуем одеваться многослойно: нижний слой одежды влагоотводящий. Лучше всего надеть термобелье с компрессионным эффектом. Средний слой одежды сохраняет тепло. Верхний — защищает от влаги. Шапка, перчатки и баф станут прекрасным дополнением.
      Делай выбор в пользу водонепроницаемой обуви с нескользящей подошвой. Отлично подойдут кроссовки для бега по пересеченной местности БЕЗ МЕТАЛИЧЕСКИХ шипов. Рекомендуем взять запасные теплые носки.`,
  },
  {
    id: 2,
    question: `Какие есть форматы Гонки Героев Зима?`,
    answer: `Масс-старт. Поставь свой личный рекорд. Это индивидуальное участие. У тебя будет собственный хронометраж. Участники делятся на «волны» по 100-150 человек. Старт забега с препятствиями осуществляется каждые 20-30 минут.
      Командный. Собери своих друзей или найди новых, став частью команды единомышленников. Условия простые: минимальный возраст участника - 18 лет, количество участников - 10 человек, ограничений по половому признаку нет. Время измеряется по последнему участнику команды, пришедшему на финиш.
      Чемпионат. Соревнование с профессиональным судейством и индивидуальным хронометражем. Участвуя в чемпионате, ты соревнуешься за призовые места. Участие в чемпионате дает тебе шанс попасть в Суперфинал и на Чемпионат Мира и Европы по OCR. В формате Чемпионата доступны две категории: индивидуальный забег Age (18-29, 30-30, 40+) и Pro.`,
  },
  {
    id: 3,
    question: `Что нужно для участия в Гонке Героев Зима?`,
    answer: <>Принять участие может любой желающий от 18 лет. В Гонке Героев Зима нет никаких ограничений по уровню физической подготовки, полу, возрасту или другим критериям.
      Мы советуем приехать на регистрацию за 1,5 часа до старта. При регистрации важно показать билет участника в распечатанном или электронном виде, удостоверении личности и <a target="_blank" rel="noopener noreferrer" href="https://faq.heroleague.ru/ru/knowledge-bases/4/articles/20-kakaya-spravka-trebuetsya-dlya-uchastiya-v-gonke-geroev">медицинскую справку.</a></>,
  },
  {
    id: 4,
    question: `Я купил билет. Как узнать всю информацию о моем участии?`,
    answer: `За два дня до мероприятия ты получишь на указанный им e-mail памятку. В ней ты найдешь расписание мероприятия, расписание стартов, информацию по безопасности и подробный маршрут к месту мероприятия. Если ты вдруг не нашел письма с памяткой, советуем проверить папку Спам.`,
  },
  {
    id: 5,
    question: `В чем отличия от обычной Гонки Героев?`,
    answer: `Это два похожих, но все-таки разных мероприятия. Трасса Гонки Героев Зима короче. Но это не значит, что она менее интенсивная или интересная. Все препятствия сконструированы так, чтобы каждый участник в условиях минусовой температуры, при наличии снега и в зимней экипировке смог их преодолеть. Также основным отличием является то, что зимней Гонке полностью отсутствуют водные препятствия, но есть препятствия сделанные из снега или льда. Поэтому тебе не стоит переживать, что ты можешь промокнуть. Мы предусмотрели все моменты для твоего комфорта.`,
  },
  {
    id: 6,
    question: `Какой уровень подготовки необходим для участия?`,
    answer: `Любой. Преодолеть дистанцию и препятствия под силу каждому. Разделение на уровни сложности, которое мы указали, условно. Это нужно для помощи в выборе формата участия. Трасса для всех участников одинаковая, а в форматах Командный и Масс-Старт препятствия можно обходить.`,
  },
  {
    id: 7,
    question: `Какие удобства есть на Гонке Героев Зима?`,
    answer: `Во время Гонки Героев Зима мы обеспечиваем каждого участника комфортными условиями, чтобы он запомнил это приключение ярким и веселым. Во время Гонки у каждого участника есть возможность выпить воды. На мероприятии всегда дежурят несколько машин скорой помощи и не менее 20 военных санитаров. А чтобы ты не замерз, тебя ждут горячая еда и чай, теплые раздевалки и согревающий настрой.`,
  },
];

export { faqQuestions };
