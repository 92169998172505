import styles from './Description.module.scss';
import Container from '../Container/Container';
import DescVideo from './DescVideo/DescVideo';
import Marquee from 'react-double-marquee';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


function Description() {
  const descRef = useRef(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    return () => {
      ScrollTrigger.getAll().forEach((instance) => {
        instance.kill();
      });

      gsap.killTweensOf(window);
    };
  }, []);

  useEffect(() => {
    const topEl = descRef.current;
    const options = {
      trigger: topEl,
      pin: true,
      pinSpacing: false,
      scrub: true,
    };

    ScrollTrigger.matchMedia({
      "(min-width: 768px) and (max-height: 990px)": function () {
        gsap.to(
          topEl,
          {
            scrollTrigger: {
              ...options,
              start: "bottom bottom",
            }
          },
        )
      },
      "(min-width: 768px) and (min-height: 990px)": function () {
        gsap.to(
          topEl,
          {
            scrollTrigger: {
              ...options,
              start: "center 100px top",
            }
          },
        )
      }
    })
  }, []);

  return (
    <section className={styles.description} ref={descRef}>
      <div className={styles.hashtagWrap}>
        <Marquee>
          <span className={styles.hashtag}>
            #зимажара #зимажара #зимажара #зимажара #зимажара #зимажара #зимажара #зимажара #зимажара #зимажара #зимажара
          </span>
        </Marquee>
      </div>

      <div className={styles.bgWrap}>
        <Container>
          <div className={styles.descWrapper}>
            <div className={styles.content}>
              <h2 className={styles.title}>
                Г<span className={styles.smile}>о</span>нка Героев Зима — это жаркий забег с препятствиями, на котором нет шансов замерзнуть!
              </h2>

              <div className={styles.videoWrapMobile}>
                <DescVideo />
              </div>

              <p className={styles.text}>
                Выглядит всё сурово и морозно, но только на первый взгляд. Каждому участнику предстоит преодолеть полосу
                препятствий, которая специально разработана для зимних условий, всех уровней подготовки и огненного настроения.
              </p>
              <p>
                <strong>Готов повеселиться и разжечь огонь внутри?</strong>
              </p>
              <a className={styles.anchor} href="#formats">Принять участие</a>
            </div>
            <div className={styles.videoWrapPc}>
              <DescVideo />
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Description;
