import styles from './BuyEventPopup.module.scss';
import Accordion from './Accordion/Accordion';
// import AccordionTeam from './Accordion/AccordionTeam';
// import { useShallowEqualSelector } from 'customHooks/useShallowEqualSelector';
import { times } from '../../../utils/times';
import Popup from '../popup/Popup';
import logo from '../../../assets/images/svg/zima_logo.svg';

const BuyEventPopup = ({ statePopupHandler, formats }) => {
  return (
    <Popup handleStatePopup={statePopupHandler} outsideStyles={styles}>
    {
      formats.length ?
      <>
        <div className={styles.wrapper}>
          <div className={styles.top}>
            <div className={styles.topBorder}>
              <img className={styles.logo} src={logo} width="55" height="54" alt="Логотип Trail"/>
              <div className={styles.titleWrap}>
                <h2 className={styles.title}>{formats[0].address}</h2>
              </div>
              <p className={styles.data}>{times.getDayOfWeek(formats[0].start_time)}</p>
            </div>
          </div>
          <div className={styles.mainContent}>
            <h3 className={styles.mainContent_title}>Выберите формат</h3>

            {formats.map(format => (
              <Accordion
                key={format.public_id}
                format={format}
                state={false}
              />
            ))}

            {/*{teams.length ? teams.map((team, i) => (*/}
            {/*  <AccordionTeam*/}
            {/*    key={i}*/}
            {/*    teams={teams}*/}
            {/*    team={team}*/}
            {/*    state={false}*/}
            {/*  />*/}
            {/*)) : null}*/}
          </div>

        </div>
      </>
      : null }
    </Popup>
  )
}

export default BuyEventPopup;
