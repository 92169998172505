import styles from './DescVideo.module.scss';
import videoPreview from '../../../assets/images/video-preview2.jpg';
import videoGgwinter from '../../../assets/video/ggwinter2.mp4';
import { useRef } from 'react';

function DescVideo() {
  const videoRef = useRef();
  const videoBtnRef = useRef();

  function stateVideo() {
    videoRef.current.play();
    videoBtnRef.current.style.display = 'none';
    videoRef.current.addEventListener('play', changeStateVideo);
    videoRef.current.addEventListener('pause', pauseVideo);
  }

  function changeStateVideo () {
    this.controls = 'controls';
  }

  function pauseVideo() {
    this.controls = '';
    videoBtnRef.current.style.display = '';
  }

  return (
    <div className={styles.video}>
      <video
        ref={videoRef}
        data-video="video"
        poster={videoPreview}
        src={videoGgwinter}
        onClick={stateVideo}
      />
      <button
        ref={videoBtnRef}
        className={styles.videoBtn}
        data-video="btn"
        onClick={stateVideo}
      >
        <span className="visually-hidden">Включить видео</span>
      </button>
      <p className={styles.songName}>The Offspring - You're Gonna Go Far, Kid</p>
    </div>
  );
}

export default DescVideo;
