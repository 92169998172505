import styles from './Footer.module.scss';
import vkLogo from '../../assets/images/svg/vk-logo-footer.svg';
import tgLogo from '../../assets/images/svg/icon-telegram.svg';
import logo from '../../assets/images/logo-lh.svg';
import { footerList } from './footer-data';
import { routes } from '../../const/const';
import Container from '../Container/Container';

const renderItem = (item) => {
  switch (item.render) {
    case `externalLink`:
      return <a href={item.route}
                className={styles.link_item}
                target="_blank"
                rel="noopener noreferrer"
      >{item.name}</a>;
    case `socialList`:
      return <ul className={styles.socials}>
        <li className={styles.social}>
          <a href="https://vk.com/heroleague" target="_blank" rel="noopener noreferrer"><img src={vkLogo} alt="vk"/></a>
        </li>
        <li className={styles.social}>
          <a href="https://t.me/herorace_info" target="_blank" rel="noopener noreferrer"><img src={tgLogo} alt="tg"/></a>
        </li>
      </ul>;
    default:
      return <a className={styles.link_item} href={`https://heroleague.ru${routes[item.route]['path']}`}>{item.name}</a>;
  }
};

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.header}>
          <span className={styles.title}>Меню</span>

        </div>
        <div className={styles.wrapper}>
          <ul className={styles.links_list}>
            {footerList.map(item => <li key={item.name}
                                        className={styles.links_item}>{renderItem(item)}</li>)}
          </ul>
          <div className={styles.copyright_block}>
            <img src={logo} alt="Лига Героев"/>
            <p className={styles.copyright}>АНО “Гонка Героев”<br />ООО “Лига Героев Спорт Проджектс”</p>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
