export const footerList = [
  { route: `https://heroleague.ru/shop`, name: `Интернет-магазин`, render: `externalLink` },
  { route: `https://heroleague.ru/events/results/`, name: `Результаты`, render: `externalLink` },
  { route: `corporate`, name: `Корпоративным клиентам` },
  { route: `https://career.heroleague.ru/`, name: `Карьера`, render: `externalLink` },
  { route: `news`, name: `Новости` },
  { route: `calendar`, name: `Календарь мероприятий` },
  { route: `partners`, name: `Партнерам` },
  { route: `/files/soglasie.pdf`, name: `Политика конфиденциальности`, render: `externalLink` },
  { route: `https://faq.heroleague.ru/`, name: `FAQ`, render: `externalLink` },
  { route: `/about/med-spravka`, name: `Медицинская справка`, render: `externalLink` },
  { route: `about`, name: `О Лиге Героев` },
  { render: `socialList`, name: `Список соцсетей` },
];