import HiddenScroll from '../../common/hide-scroll/HideScroll';
import { useState } from 'react';

function Popup({ handlePopup, answer}) {
  const [isOpenPopup, setIsOpenPopup] = useState(true);

  const handleClosePopup = () => {
    setIsOpenPopup(false);

    // так-же нужно менять время анимации в стилях, класс: popup--hide
    setTimeout(() => handlePopup(false), 300);
  };

  return (
    <>
      <HiddenScroll />
      <section className={`popup ${!isOpenPopup ? `popup--hide` : ``}`}>
        <div className="popup__wrapper">
          <b className="popup__title">Вопрос № <span>{answer.id + 1}</span></b>
          <p className="popup__answer">{answer.answer}</p>
          <button className="popup__close" onClick={handleClosePopup}>Понятно</button>
          <button className="popup__close-btn" onClick={handleClosePopup}>
            <span className="visually-hidden">Закрыть</span>
          </button>
        </div>
      </section>
    </>
  );
}

export default Popup;
