import { useState } from 'react';
import styles from './Accordion.module.scss';
import BuyEventInfo from '../BuyEventInfo/BuyEventInfo';


const Accordion = ({format, logo, state}) => {
  const { title } = format;
  const [isOpenAccordion, setOpenAccordion] = useState(state);
  const handleTogglePanel = () =>  setOpenAccordion(!isOpenAccordion);

  return (
    <div className={`${styles.accordion} ${isOpenAccordion ? styles.accordionOpened : ``}`}>
      <div className={styles.accordionPanel} onClick={handleTogglePanel}>
        <h3 className={styles.panelTitle}>{title || `Формат`}</h3>
      </div>

      <BuyEventInfo
        id={format.public_id}
        format={format}
        styles={styles}
        logo={logo}
      />
    </div>
  )
}

export default Accordion;
