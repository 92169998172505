import styles from './Popup.module.scss';
import { useEffect } from 'react';
import HiddenScroll from '../../common/hide-scroll/HideScroll';

const Popup = ({ handleStatePopup, children, outsideStyles }) => {
  const ESC_KEYCODE = 27;

  const closePopup = () => {
    handleStatePopup(false);
  }

  useEffect(() => {
    const onKeydown = e => {
      e.keyCode === ESC_KEYCODE && closePopup();
    };

    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  });

  return (
    <>
      <section className={styles.overlay} onClick={closePopup} role="dialog">
        <div className={`${outsideStyles.container} ${styles.container}`} onClick={(e) => e.stopPropagation()}>
          <button
            className={`${styles.closeBtn} ${outsideStyles.closeBtn}`}
            type="button"
            onClick={closePopup}
          >
            <span className="visually-hidden">Закрыть</span>
          </button>

          {children}

        </div>
      </section>

      <HiddenScroll />
    </>
  )
}

export default Popup;
