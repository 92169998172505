import styles from './Partners.module.scss';
import Container from '../Container/Container';
import {useSelector} from "react-redux";
import {getFormatCity} from "../../store/app/selectors";
import {useState} from "react";

function Partner({ data }) {
  const [isVisibleInfo, setIsVisibleInfo] = useState(false)

  const handleClickAdInfo = (evt) => {
    evt.preventDefault()

    setIsVisibleInfo(!isVisibleInfo)
  }

  return (
    <a {...(data.hasOwnProperty('link') && {href: data.link})} target="_blank" rel="noopener noreferrer">
      <div className={styles.partner}>
        <img className={styles.logo} src={data.picture_main} width={100} alt={data.title || ''} />

        {isVisibleInfo && (
          <div className={styles.partnerInfo} onClick={(evt) => evt.preventDefault()}>
            <p>{data?.reklama?.name}</p>
            <p>ИНН: {data?.reklama?.inn}</p>
            <p>ERID: {data?.reklama?.gov_id}</p>
          </div>
        )}

        {'reklama' in data && Object.keys(data?.reklama).length > 0 && (
          <button className={styles.infoToggle} onClick={handleClickAdInfo}>
          <span className="visually-hidden">
            {isVisibleInfo ? 'Скрыть' : 'Показать'} дополнительную информацию
          </span>
          </button>
        )}
      </div>
    </a>
  )
}

function Partners() {
  const city = useSelector(getFormatCity);

  if (!city?.banners || city?.banners?.length === 0) return null

  return (
    <section className="partners">
      <Container>
        <h2 className={styles.title}>Спонсоры и&nbsp;партнеры</h2>
        <div className="partners__rel">
          <div className="partners__container">
            <div className={styles.list}>
              {city.banners.map((item) => (
                <Partner data={item} key={item.public_id}/>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Partners;
