import styles from './Features.module.scss';
import Container from '../Container/Container';
import { useState } from 'react';
import { features } from './featuresData';
import Title from '../Title/Title';


function Features() {
  const [activePanel, setActivePanel] = useState(1);

  const handleClickAccordion = (evt) => {
    const index = Number(evt.target.dataset.index);

    if (index === activePanel) setActivePanel(null);
    else setActivePanel(index);
  };

  return (
    <section className={styles.features}>
      <div className={styles.bgWrap}>
        <Container>
          <Title shadowTitle={`Сомневаешься?`}>Сомневаешься?</Title>
          <ul className={styles.list}>
            { features.map((item) => (
              <li className={styles.item} key={item.id}>
                <h3
                  className={`${styles.itemTitle} ${activePanel === item.id ? styles.itemTitleOpened : null}`}
                  onClick={handleClickAccordion}
                  data-index={item.id}
                >
                  {item.title}
                  <span className={styles.titleIcon} style={{backgroundImage: `url("${item.icon}")`}} />
                </h3>

                {(activePanel && activePanel === item.id) ? <p className={styles.itemPanel}>{item.text}</p> : null}
              </li>
            )) }
          </ul>
        </Container>
      </div>
    </section>
  );
}

export default Features;
