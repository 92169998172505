import styles from './Levels.module.scss';
import Container from '../Container/Container';
import { levels } from './levelsData';


function Levels() {
  return (
    <section className={styles.levels}>
      <Container>
        <h2 className={styles.title}>
          <span>Готов разжечь</span> <span>огонь внутри? ❤️🔥</span>
        </h2>

        <ul className={styles.list}>
          { levels.map((level) => (
            <li key={level.id}>
              <img className={styles.icon} src={level.icon} alt={level.label}/>
              <h3 className={styles.levelTitle}>{level.label}</h3>
              <p>{level.desc}</p>
            </li>
          )) }
        </ul>
      </Container>
    </section>
  );
}

export default Levels;
