import styles from './Options.module.scss';
import { options } from './optionsData';
import Container from '../Container/Container';
import Marquee from 'react-double-marquee';


const generateOptions = (options) => {
  const generatedOptions = [];

  for (let i = 0; i < 3; i++) {
    generatedOptions.push(options[0]);
    generatedOptions.push(options[1]);
  }

  return generatedOptions.map((option, index) => <span className={styles.option}><img className={styles.optionImg} src={option.icon} alt="иконка"/>{option.label}</span>);
};

function Options() {

  return (
    <section className={styles.options}>
      <Container>
        <h2 className={styles.title}>Мы обо всем позаботились</h2>
      </Container>

      <div className={styles.list}>
        { options.map((item, index) => (
          <div className={styles.item} key={item[0].id}>

            <div className={styles.itemWrapPc}>
              <Marquee childMargin={0} direction={index % 2 === 0 ? `right` : `left`}>
                {generateOptions(item)}
              </Marquee>
            </div>

            <div className={styles.itemWrapMobile}>
              <span className={styles.option}><img className={styles.optionImg} src={item[0].icon} alt="иконка"/>{item[0].label}</span>
              <span className={styles.option}><img className={styles.optionImg} src={item[1].icon} alt="иконка"/>{item[1].label}</span>
            </div>

          </div>
        )) }
      </div>


    </section>
  );
}

export default Options;
