import { combineReducers } from 'redux';
import { app } from './app/app';

const NameSpace = {
  APP: `APP`,
};

export { NameSpace };
export default combineReducers({
  [NameSpace.APP]: app,
});
