import styles from './Gallery.module.scss';

import gal1 from '../../assets/images/gallery/gal1.png';
import gal2 from '../../assets/images/gallery/gal2.png';
import gal3 from '../../assets/images/gallery/gal3.png';
import gal4 from '../../assets/images/gallery/gal4.png';
import gal5 from '../../assets/images/gallery/gal5.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import Container from '../Container/Container';

// Установка модулей Swiper
SwiperCore.use([Navigation]);

function Gallery() {
  const sliderParams = {
    speed: 600,
    slidesPerView: `auto`,
    loop: true,
    allowTouchMove: true,

    navigation: {
      nextEl: `${styles.btn} ${styles.btnPrev}`,
      prevEl: `${styles.btn} ${styles.btnNext}`,
    },
  }
  return (
    <section className={styles.gallery}>
      <Container>
        <h2 className={styles.title}>И это ещё не всё</h2>

        <p className={styles.desc}>
          Хочешь узнать больше, смотри фотографии с Гонки Героев Зима на наших страницах VK и Telegram!
        </p>
      </Container>

      <div className={styles.galleryRel}>
        <Swiper {...sliderParams} className={styles.galleryContainer}>
          <SwiperSlide className={styles.slide}>
            <img src={gal1} alt="Slide 1" />
          </SwiperSlide>
          <SwiperSlide className={styles.slide}>
            <img src={gal2} alt="Slide 2" />
          </SwiperSlide>
          <SwiperSlide className={styles.slide}>
            <img src={gal3} alt="Slide 3" />
          </SwiperSlide>
          <SwiperSlide className={styles.slide}>
            <img src={gal4} alt="Slide 4" />
          </SwiperSlide>
          <SwiperSlide className={styles.slide}>
            <img src={gal5} alt="Slide 5" />
          </SwiperSlide>
        </Swiper>
        <div className={styles.controls}>
          <button className={`${styles.btn} ${styles.btnPrev}`} />
          <button className={`${styles.btn} ${styles.btnNext}`} />
        </div>
      </div>
    </section>
  );
}

export default Gallery;
