import { useState } from 'react';
import { BASKET_COUNT } from '../const/const';

export const useCount = (initState) => {
  const [count, setCount] = useState(initState);

  const increment = () => {
    if (count >= BASKET_COUNT.max_count) return;
    else setCount(count + initState);
  };

  const decrement = () => {
    if (count <= BASKET_COUNT.min_count) return;
    else setCount(count - initState);
  };

  return [count, increment, decrement];
}