import icon1 from '../../assets/images/icon-level1.png';
import icon2 from '../../assets/images/icon-level2.png';
import icon3 from '../../assets/images/icon-level3.png';


const levels = [
  {
    id: 0,
    label: `Новичок`,
    desc: `Ты — уже Герой, если решился выйти на старт. Для первого раза на Гонке Героев Зима мы советуем тебе бежать 
      с друзьями. Собери свою команду или вместе выходите на Массовый старт — в компании тебе точно будет веселее и увереннее!`,
    icon: icon1,
  },
  {
    id: 1,
    label: `Любитель`,
    desc: `Ты уже знаком с забегами с препятствиями или просто ведёшь активный образ жизни. Тебя ждёт новое достижение! 
      Ты готов бежать с друзьями или встретить новых на старте — в любом случае ты знаешь, что тебя ждет невероятное веселье!`,
    icon: icon2,
  },
  {
    id: 2,
    label: `Профи`,
    desc: `Ты уже знаешь,на что рассчитывать.Для тебя Гонка Героев Зима — еще один вызов и +1 достижение  в коллекцию. 
      Мы восхищаемся тобой и болеем за то, чтобы ты побил личный рекорд и выиграл чемпионский  титул!`,
    icon: icon3,
  },
];

export {
  levels,
};
