import styles from './CityChoice.module.scss';
import Select from 'react-select';
import './CityChoice.scss';


function CityChoice({ cityOptions, defaultCityOption, onChangeCity }) {
  return (
    <section className={styles.cityChoice}>
      { cityOptions.length > 0 &&
        <Select
          className="citySelect"
          classNamePrefix="citySelect"
          options={cityOptions}
          value={defaultCityOption}
          placeholder="Выберите город"
          onChange={onChangeCity}
          isSearchable={false}
          closeMenuOnScroll={false}
        />
      }
    </section>
  );
}

export default CityChoice;
