import styles from './Faq.module.scss';
import { faqQuestions } from './faq-data';
import Popup from './popup/Popup';
import { useState } from 'react';

function Faq() {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [openAnswer, setOpenAnswer] = useState({});

  const handleOpenPopup = (answer) => {
    setOpenAnswer(answer);
    setIsOpenPopup(true);
  }

  return (
    <>
      <section className="faq container container--big">
        <h2 className={styles.title}>Вопрос-ответ</h2>

        <ul className="faq__list">
          { faqQuestions.map((item) => (
            <li className="faq__item" key={item.id + 1}>
              <h3 className="faq__sub">Вопрос №{item.id + 1}</h3>
              <p className="faq__text">{item.question}</p>
              <button className="faq__btn" onClick={() => handleOpenPopup(item)}>Ответ</button>
            </li>
          )) }
        </ul>
      </section>

      { isOpenPopup && <Popup handlePopup={setIsOpenPopup} answer={openAnswer}/> }
    </>
  );
}

export default Faq;
