import './App.scss';
import history from '../../history';
import { Route, Router, Switch } from 'react-router-dom';
import MainScreen from '../../pages/MainScreen';
import { cityRoutes } from '../../const/routes';
import { connect } from 'react-redux';

function App() {
  return (
    <Router history={history}>
      <Switch>
        { cityRoutes.map((route) => (
          <Route exact path={`${route.path}`} key={route.id}>
            <MainScreen cityIdScreen={route.cityId} />
          </Route>
        )) }
      </Switch>
    </Router>
  );
}

export { App }
export default connect()(App);
