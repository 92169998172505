import styles from './Corp.module.scss';
import Container from '../Container/Container';
import bgMembers from '../../assets/images/corp-bg.png';


function Corp() {
  return (
    <section className={styles.corp}>
      <img className={styles.membersImg} src={bgMembers} alt="участники"/>
      <Container>
        <h2 className={styles.title}>Корпоративное участие</h2>
        <div className={styles.content}>
          <p className={styles.text}>
            У тебя уже есть команда,с которой ты готов принять вызов Гонки Героев Зима?
          </p>
          <p className={styles.text}>
            Чтобы подать заявку на корпоративное участие — напиши письмо на почту <a className={styles.linkMail} href="mailto:corp@heroleague.ru">corp@heroleague.ru</a>.
            Вместе вы сможете представить свою компанию или устроить соревнования между собой.
          </p>
          <p className={styles.text}>
            Оставь заявку, чтобы получить пропуск в морозное приключение для себя и своих коллег.
          </p>
        </div>

        <a className={styles.btn} href="#formats">Принять участие</a>
      </Container>
    </section>
  );
}

export default Corp;
