import styles from './Container.module.scss';


function Container({children, outsideStyles}) {
  return (
    <div className={`${styles.container} ${outsideStyles}`}>
      {children}
    </div>
  );
}

export default Container;
